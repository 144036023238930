import type {PropsWithChildren, ReactElement} from 'react';
import type {SiteConfig} from "@uxu/utils/common/types";
import type {QueryClient, DehydratedState} from "@tanstack/react-query";
import {
    ToastChunksContext,
    ProviderToastChunks,
    toastChunksInitial,
    CookieDetails,
    AllowCookies
} from '../components/organisms/toast';
import {ReactQueryProvider} from './provider.reactQuery';
import {SiteConfigProvider} from './provider.configSite';
import {SEOProvider} from './provider.seo';

type WrapperProvidersProps = PropsWithChildren<{
    siteConfig: SiteConfig,
    queryClient: QueryClient,
    dehydratedState?: DehydratedState
}>;

export function WrapperProviders({children, siteConfig, queryClient, dehydratedState}: WrapperProvidersProps): ReactElement {
    const toastChunks = toastChunksInitial([
        {
            shouldBeIncluded: !siteConfig.client.cookieConsentSettings.ads,
            id: 'cookieInfoDefault',
            props: {
                text: "Korzystamy z plików cookie, aby mierzyć i ulepszać Twoje doświadczenia.",
                actions: [
                    {
                        Component: <CookieDetails ToastChunksContext={ToastChunksContext} siteConfig={siteConfig}/>
                    },
                    {
                        Component: <AllowCookies ToastChunksContext={ToastChunksContext}/>,
                        type: 'remove'
                    }
                ]
            }
        }
    ]);
    return (
        <SiteConfigProvider siteConfig={siteConfig}>
            <ProviderToastChunks toastChunksInitial={toastChunks}>
                <SEOProvider siteConfig={siteConfig}>
                    <ReactQueryProvider dehydratedState={dehydratedState} queryClient={queryClient}>
                        {children}
                    </ReactQueryProvider>
                </SEOProvider>
            </ProviderToastChunks>
        </SiteConfigProvider>
    );
}
