import {useEffect} from 'react';

export function GoogleTagManagerHead({googleTagManagerId}: { googleTagManagerId?: string }): null {
    if (Boolean(!googleTagManagerId)) return null;

    useEffect(() => {
        const gtmScript = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${googleTagManagerId}');
    `;

        const script = document.createElement('script');
        script.innerHTML = gtmScript;

        document.head.append(script);

        return () => {
            script.remove();
        };
    }, [googleTagManagerId]);

    return null;
}
