import type { NextPageContext } from 'next';
import type { DocumentContext } from "next/document";
import {Client} from "../../../../common/types";


export function getHeaderCookieConsentSettings(ctx: NextPageContext | DocumentContext): Client['cookieConsentSettings'] {
  const ads: boolean = ctx.req?.headers['uxu-header-consent-settings-ads'] === 'true';
  const analytics: boolean = ctx.req?.headers['uxu-header-consent-settings-analytics'] === 'true';

  return {
    ads,
    analytics,
  };
}
