import type {FooterProps} from "@uxu/design-system/components/templates/footer/types";
import {generateUniqueId} from "@uxu/utils/common/other";

export const footerConfig: FooterProps = {
    footerColumns: [
        {
            id: generateUniqueId(),
            header: "Social media",
            links: [
                {
                    id: generateUniqueId(),
                    title: "LinkedIn",
                    linkPath: "https://www.linkedin.com/in/pawel-niedzwiecki"
                },
                {
                    id: generateUniqueId(),
                    title: "GitHub",
                    linkPath: "https://github.com/pawel-niedzwiecki"
                },
                {
                    id: generateUniqueId(),
                    title: "FaceBook",
                    linkPath: "https://www.facebook.com/profile.php?id=61565800479632"
                },
                {
                    id: generateUniqueId(),
                    title: "Twitter",
                    linkPath: "https://x.com/p_niedzwiecki"
                },
            ]
        },
        {
            id: generateUniqueId(),
            header: "Moje serwisy",
            links: [
                {
                    id: generateUniqueId(),
                    title: "polski.dev",
                    linkPath: "https://www.polski.dev"
                },
                {
                    id: generateUniqueId(),
                    title: "wtrasie.pl",
                    linkPath: "https://wtrasie.pl"
                },
                {
                    id: generateUniqueId(),
                    title: "aua.pl",
                    linkPath: "https://wtrasie.pl"
                },
                {
                    id: generateUniqueId(),
                    title: "telbook.info",
                    linkPath: "https://telbook.info"
                }
            ]
        },
        {
            id: generateUniqueId(),
            header: "Informacje prawne",
            links: [
                {
                    id: generateUniqueId(),
                    title: "Regulamin",
                    linkPath: "/terms"
                },
                {
                    id: generateUniqueId(),
                    title: "Polityka Prywatności",
                    linkPath: "/privacy-policy"
                }
            ]
        }
    ],
};
