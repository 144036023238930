export enum ContentPartEnum {
		EMPTY,
		AD,
		PARAGRAPH,
		HEADER,
		IMG,
		QUOTE,
		LIST,
		CODEBLOCK,
		OPINION,
		EMBED,
		TABLE,
		GALLERY,
		EMBEDYOUTUBE,
		CHARTBARSERIESXY,
		CHARTBARLINESXY,
		QUIZ
}
