import {GetAuthDataProps} from "./types";
import {HeadersName} from "../../../../../common/enums";
import type {SiteConfig} from "../../../../../common/types";
import {decodeTokenJWT} from "../../../../../common/other";

export function getAuthData({ctx}: GetAuthDataProps): SiteConfig['client']['auth'] {
    const {req, res} = ctx;
    const headerAuth = req?.headers['authorization'] || req?.headers[HeadersName.AuthBearerToken];
    const token = typeof headerAuth === 'string' && headerAuth.startsWith('Bearer ') ? headerAuth.slice(7) : undefined;

    if (!token) {
        return {
            isAuth: false,
        }
    }

    const secret = process.env.NEXT_SECRET_AUTH_SECRET_JWT_KEY || "";
    const payloadData = decodeTokenJWT(token, secret);

    if (!payloadData) {
        return {
            isAuth: false,
        }
    }


    return {
        isAuth: true,
        userId: payloadData.user_id?.toString(),
        roleName: payloadData.role_name,
        avatarUrl: payloadData.avatar_url,
    };
}
