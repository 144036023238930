import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60,     // Time that data is considered "fresh" (1 minute)
            refetchOnWindowFocus: false, // Do not redownload data after switching windows
            refetchOnReconnect: false, // Don't redownload data after losing your connection
            retry: 2,                 // The number of attempts to requery on error
            retryDelay: 1000,  // A delay of 1 second before the query is retried
        },
    },
});