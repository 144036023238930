export enum ProgrammingLanguage {
		Abap = "abap",
		ActionScript = "actionscript",
		Ada = "ada",
		Arduino = "arduino",
		AutoIt = "autoit",
		C = "c",
		Clojure = "clojure",
		Cs = "cs",
		Cpp = "cpp",
		CoffeeScript = "coffeescript",
		CSharp = "csharp",
		Css = "css",
		Cuda = "cuda",
		D = "d",
		Dart = "dart",
		Delphi = "delphi",
		Elixir = "elixir",
		Elm = "elm",
		Erlang = "erlang",
		Fortran = "fortran",
		FoxPro = "foxpro",
		FSharp = "fsharp",
		Go = "go",
		GraphQL = "graphql",
		Gql = "gql",
		Groovy = "groovy",
		Haskell = "haskell",
		Haxe = "haxe",
		Html = "html",
		Java = "java",
		JavaScript = "javascript",
		Json = "json",
		Julia = "julia",
		Jsx = "jsx",
		Js = "js",
		Kotlin = "kotlin",
		Latex = "latex",
		Lisp = "lisp",
		LiveScript = "livescript",
		Lua = "lua",
		Mathematica = "mathematica",
		Makefile = "makefile",
		Matlab = "matlab",
		ObjectiveC = "objectivec",
		ObjectPascal = "objectpascal",
		Ocaml = "ocaml",
		Octave = "octave",
		Perl = "perl",
		Php = "php",
		PowerShell = "powershell",
		Prolog = "prolog",
		Puppet = "puppet",
		Python = "python",
		Qml = "qml",
		R = "r",
		Racket = "racket",
		RestructuredText = "restructuredtext",
		Rest = "rest",
		Ruby = "ruby",
		Rust = "rust",
		Sass = "sass",
		Less = "less",
		Scala = "scala",
		Scheme = "scheme",
		Shell = "shell",
		Smalltalk = "smalltalk",
		Sql = "sql",
		StandardMl = "standardml",
		Sml = "sml",
		Swift = "swift",
		Tcl = "tcl",
		Tex = "tex",
		Text = "text",
		Tsx = "tsx",
		Ts = "ts",
		TypeScript = "typescript",
		Vala = "vala",
		VbNet = "vbnet",
		Verilog = "verilog",
		Vhdl = "vhdl",
		Xml = "xml",
		XQuery = "xquery",
		Yaml = "yaml"
}