import {useState, createContext, type PropsWithChildren, type ReactElement} from 'react';
import type {SiteConfig, SiteConfigContextProps} from '@uxu/utils/common/types';
import {SiteIdEnums} from '@uxu/utils/common/enums';

const initialSiteConfigContext: SiteConfigContextProps = {
    config: {
        admin: {},
        marketingToolsConfig: {},
        port: 3000,
        projectName: '',
        site: {
            id: SiteIdEnums.DEFAULT,
            locale: '',
            domain: '',
            slug: "/",
            brand: "uxu",
            shortBrand: "uxu",
            defaultCover: '/defaultCover.png',
            canonicalUrl: '',
            title: '',
            shortname: '',
            description: '',
            authEnabled: false,
            authMatcher: [],
            switchTheme: true,
            images: [],
            assets: {
                cdn: []
            }
        },
        social: {
            facebook: {
                pageId: ''
            }
        },
        client: {
            locale: 'pl',
            platform: {
                isDesktop: false,
                isMobile: false,
            },
            osInfo: {
                isWindows: false,
                isLinux: false,
                isMacOS: false,
            },
            cookieConsentSettings: {
                ads: false,
                analytics: false
            },
            auth: {
                isAuth: false,
            },
        }
    },
    setConfig: (): void => { /* empty */
    }
};

export const SiteConfigContext = createContext<SiteConfigContextProps>(initialSiteConfigContext);

type SiteConfigProviderProps = PropsWithChildren<{ siteConfig: SiteConfig }>;

export function SiteConfigProvider({children, siteConfig}: SiteConfigProviderProps): ReactElement {
    const [config, setConfig] = useState<SiteConfig>(siteConfig);
    return (
        <SiteConfigContext.Provider value={{config, setConfig}}>
            {children}
        </SiteConfigContext.Provider>
    );
}
