export enum PhoneNetworkPL {
  CenterNet = 'CenterNet',
  CyfrowyPolsat = 'Cyfrowy Polsat',
  FMGroupMobile = 'FM Group Mobile',
  Heyah = 'Heyah',
  Inea = 'Inea',
  KluczMobile = 'Klucz Mobile',
  LajtMobile = 'Lajt Mobile',
  Lycamobile = 'Lycamobile',
  MultimediaPolska = 'Multimedia Polska',
  NAUMobile = 'NAU Mobile',
  NetiaMobile = 'Netia Mobile',
  Nju = 'Nju',
  Nordisk = 'Nordisk',
  Orange = 'Orange',
  Plus = 'Plus',
  PremiumMobile = 'Premium Mobile',
  RedBullMobile = 'Red Bull Mobile',
  SatFilm = 'Sat Film',
  TMobile = 'T-Mobile',
  Tele25 = 'Tele25',
  TeleCube = 'TeleCube',
  Toya = 'Toya',
  TuBiedronka = 'TuBiedronka',
  UPCPolska = 'UPC Polska',
  Vectone = 'Vectone',
  Vectra = 'Vectra',
  VirginMobile = 'Virgin Mobile',
  WNaszejRodzinie = 'W naszej Rodzinie',
  WRodzinie = 'WRodzinie',
  WhiteMobile = 'White Mobile',
  iPlus = 'iPlus',
  Unknown = 'unknown'
}
