import type {PropsWithChildren, ReactElement} from 'react';
import {QueryClientProvider, HydrationBoundary} from '@tanstack/react-query';
import type {QueryClient, DehydratedState} from '@tanstack/react-query';


type ReactQueryProviderProps = PropsWithChildren<{ queryClient: QueryClient, dehydratedState?: DehydratedState }>

export function ReactQueryProvider({children, queryClient, dehydratedState}: ReactQueryProviderProps): ReactElement {
    return (
        <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={dehydratedState}>
                {children}
            </HydrationBoundary>
        </QueryClientProvider>
    )
}
