type HeaderMenuConfigTypes = Array<{ href: string, title: string }>

export const headerMenuConfig: HeaderMenuConfigTypes = [
    {href: "/", title: "home"},
    {href: "/#portfolio", title: "portfolio"},
    {href: "/#about", title: "o mnie"},
    {href: "https://polski.dev", title: "blog"},
    {href: "/contact", title: "kontakt"}
]

